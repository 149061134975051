import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import {
  colors,
  PrimaryButton,
  screenSizes,
} from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  padding: 70px 50px;
  max-width: 2000px;
  margin: auto;

  @media (max-width: ${screenSizes.largeDesktop}px) {
    padding: 50px 30px;
  }
`
const Title = styled.h1`
  font-family: Minion serif;
  margin-bottom: 30px;
  font-size: 2.2em;

  @media (max-width: ${screenSizes.tablet}px) {
    font-size: 2em;
  }
`

const BrochureButton = styled(PrimaryButton)`
  margin-top: 20px;
  font-size: 18px;
  background-color: ${colors.primaryDark} !important;
  padding: 15px 25px;
`

interface Props {
  className?: string
  title: React.ReactNode | string
  actionText: string
  onClick: () => void
}

const LPTitleFeatureBlock: FC<Props> = ({ className, title, actionText, onClick }) => {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      <BrochureButton onClick={onClick}>{actionText}</BrochureButton>
    </Container>
  )
}

export default LPTitleFeatureBlock
